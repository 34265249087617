<template>
  <div id="program-applications-body" class="">
    <div class="c-app justify-content-center mt-3">
      <div>
        <div class="mb-4">
          <div class="">
            <div class="text-center mb-2">
              <img
                src="@/assets/images/ched-header-logo-cropped.png"
                classalt=""
              />
            </div>

            <h3 class="text-center mb-0 font-weight-bold">
              CHED CARAGA PROGRAM APPLICATION
            </h3>
          </div>
        </div>
        <div class="card rounded shadow-sm">
          <div class="card-body overflow-auto" style="width: 600px;">
            <section v-if="currentStep == 1">
              <div class="">
                <h5 class="font-weight-bold text-center">
                  Please read the instructions carefully.
                </h5>
                <ol class="mt-3">
                  <li>
                    Make sure that all files must be submitted in
                    <strong class="">PDF format</strong>. Double check all
                    documents before submitting.
                  </li>
                  <li>
                    Please ensure that you submit
                    <strong>only one file for each area</strong>. In case you
                    have multiple documents, please merge them into a single
                    file before uploading.
                  </li>
                  <li>
                    It is crucial to thoroughly review and verify all the
                    information before proceeding with the submission.
                    <strong
                      >Once the form is submitted, it cannot be
                      modified.</strong
                    >
                  </li>
                </ol>

                <div class="form-check text-center">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    id="checkbox"
                    v-model="checkbox"
                  />
                  <label
                    class="form-check-label"
                    for="checkbox"
                    style="font-size: 15px;"
                    >I have read and understood the instructions.</label
                  >
                </div>
                <div class="text-center mt-3">
                  <button
                    :disabled="!checkbox"
                    class="btn btn-lg btn-primary "
                    @click="getNextId"
                  >
                    Get started
                  </button>
                </div>
              </div>
            </section>

            <section v-if="currentStep == 2">
              <div>
                <h5 class="font-weight-bold">Application Details</h5>
                <p>Ref No: PA-{{ form.id }}-2023</p>

                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label for="">Applied for</label>
                      <select
                        v-model="form.application_type"
                        class="form-control"
                        id=""
                        @change="getRequirementsPerAppType"
                      >
                        <option value="" selected disabled hidden
                          >Choose</option
                        >
                        <option value="Initial Permit (IP)"
                          >Initial Permit (IP)</option
                        >
                        <option value="Renewal Permit (RP)"
                          >Renewal Permit (RP)</option
                        >
                        <option value="Government Recognition (GR)"
                          >Government Recognition (GR)</option
                        >
                        <option value="Additional Major (AM)"
                          >Additional Major (AM)</option
                        >
                        <option value="Certificate of Program Compliance (COPC)"
                          >Certificate of Program Compliance (COPC)</option
                        >
                      </select>
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label for="">A.Y. Effectivity</label>
                      <select
                        v-model="form.academic_year"
                        name=""
                        class="form-control"
                        id=""
                      >
                        <option value="" selected disabled hidden
                          >Choose</option
                        >
                        <option
                          v-for="ay in awardYears"
                          :key="ay"
                          :value="ay"
                          >{{ ay }}</option
                        >
                      </select>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="">Name of HEI</label>
                  <select name="" v-model="hei" class="form-control" id="">
                    <option value="" selected disabled hidden>Choose</option>
                    <option v-for="hei in heis" :value="hei" :key="hei.code">{{
                      hei.name
                    }}</option>
                  </select>
                </div>

                <div class="form-group">
                  <label for="">Applied Program</label>
                  <select name="" v-model="program" class="form-control" id="">
                    <option value="" selected disabled hidden>Choose</option>
                    <option
                      v-for="program in programs"
                      :key="program.id"
                      :value="program"
                      >{{ program.program_name }}</option
                    >
                  </select>
                </div>
              </div>
            </section>

            <section v-for="(req, index) in requirements" :key="req.id">
              <div class="" v-if="currentStep == 3 + index">
                <h5 class="text-center">
                  Requirement {{ index + 1 }} of {{ requirements.length }}
                </h5>
                <h5 class="font-weight-bold mt-3">{{ req.name }}</h5>
                <p
                  style="white-space: pre-wrap; text-indent: -4px; font-size: 15px;"
                  class="mt-3"
                >
                  {{ req.description }}
                </p>
                <div class="form-group mt-5">
                  <label for="">
                    Please upload the required document.
                  </label>
                  <input
                    id="file-upload"
                    type="file"
                    accept="application/pdf"
                    class="form-control-file"
                    @change="uploadFileEvent($event, index, req)"
                  />
                  <small class="form-text text-muted">
                    <i class="bi bi-exclamation-triangle-fill mx-1"></i> Make
                    sure to upload the <strong>PDF file</strong> only.</small
                  >
                </div>

                <div v-if="fileUploadLoader" class="d-flex align-items-center">
                  <strong class="text-primary">{{ loaderMsg }}</strong>
                  <div
                    class="spinner-border text-primary spinner-border-sm ml-auto"
                    role="status"
                    aria-hidden="true"
                  ></div>
                </div>

                <div v-if="req.filename" class="">
                  <a
                    href="#"
                    target="_blank"
                    @click.prevent="openUrl(req.path)"
                  >
                    <i class="bi bi-file-pdf"></i> {{ req.filename }}
                    <i class="bi bi-check-circle-fill text-success mx-1"></i
                  ></a>
                </div>
              </div>
            </section>

            <section>
              <div v-if="currentStep == 3 + requirements.length">
                <h5 class="text-center mb-3">
                  Review your application before submitting
                </h5>
                <table class="table table-bordered table-sm">
                  <tbody>
                    <tr>
                      <td width="45%">Name of HEI</td>
                      <th>{{ hei.name }}</th>
                    </tr>
                    <tr>
                      <td>Applied Program</td>
                      <th>{{ program.program_name }}</th>
                    </tr>
                    <tr>
                      <td>Applied for</td>
                      <th>{{ form.application_type }}</th>
                    </tr>
                    <tr>
                      <td>A.Y. Effectivity</td>
                      <th>{{ form.academic_year }}</th>
                    </tr>
                    <tr>
                      <th colspan="2">REQUIRED DOCUMENTS</th>
                    </tr>
                    <tr v-for="(req, index) in requirements" :key="index">
                      <td>{{ `${index + 1}. ${req.description} ` }}</td>
                      <td>
                        <a
                          href=""
                          target="_blank"
                          @click.prevent="openUrl(req.path)"
                        >
                          <i class="bi bi-file-pdf mr-1"></i>
                          {{ req.filename }}</a
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>

            <section>
              <div v-if="currentStep == 4 + requirements.length">
                <div class="text-center mb-3">
                  <i
                    class="bi bi-check-circle-fill text-success"
                    style="font-size: 5em"
                  ></i>
                  <h3 class="font-weight-bold">Thank you!</h3>
                  <div class="mb-3">
                    Your application was successfully submitted.
                  </div>

                  <button
                    class="btn btn-success mb-3"
                    @click="showSurveySection = true"
                  >
                    Click here to print the reference letter
                  </button>
                </div>

                <div class="container" v-if="showSurveySection">
                  <p>
                    <strong>Hi there!</strong> We kindly request a few moments
                    of your time to share your experience with us by rating our
                    services.
                  </p>

                  <p>Click the link below</p>
                  <p>
                    <a
                      @click="startTimer"
                      style="font-size: 18px;"
                      class="font-weight-bold"
                      href="https://docs.google.com/forms/d/e/1FAIpQLSf54ZCey-Ua-I2h5mgg2hNLXx715LhiWMNonoAZZzvbjTRXQQ/viewform"
                      target="_blank"
                      >Client Satisfaction Survey Form</a
                    >
                  </p>

                  <p>
                    You will actively contribute to shaping the future of CHED
                    and help us create an environment that exceeds your
                    expectations.
                  </p>

                  <p>
                    The <strong>Print Button</strong> will activate after
                    filling up the form. Thank you.
                  </p>

                  <button
                    class="btn btn-primary mb-3"
                    @click="print"
                    :disabled="!timerEnabled"
                  >
                    <i class="bi bi-printer mr-1"></i> Print/Download PDF
                  </button>

                  <div v-if="submitAnotherEntry">
                    To submit another application, click
                    <a
                      href="#"
                      @click.prevent="resetForm"
                      class="font-weight-bold"
                      >here</a
                    >.
                  </div>
                </div>
              </div>
            </section>
          </div>
          <!-- end of card body -->
        </div>
        <div
          v-if="currentStep > 1 && currentStep != 4 + requirements.length"
          class="d-flex justify-content-between mb-3"
        >
          <button class="btn btn-secondary" @click="prevStep">
            Previous
          </button>
          <button
            v-if="currentStep != 1 && currentStep < 3 + requirements.length"
            :disabled="isDisabled"
            class="btn btn-primary"
            @click="nextStep"
          >
            Next
          </button>
          <button
            v-else
            class="btn btn-primary"
            @click="submit"
            :disabled="isSubmitBtnDisabled"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
    <section v-show="false" id="acknowledgement-letter">
      <div id="header" class="text-center mb-5">
        <img src="@/assets/images/ched-header-logo.png" alt="" />
      </div>
      <div id="greetings" class="text-center">
        <div class="mb-5">
          <img src="@/assets/images/check-mark.png" class="mb-3" alt="" />
          <h4>Your program application was submitted successfully.</h4>
        </div>
        <div>
          <h3>Application Reference No.</h3>
          <h1 class="font-weight-bold">PA-{{ form.id }}-2023</h1>
          <p>
            (Use this reference number when making a payment on the Landbank
            LinkBiz Portal)
          </p>
        </div>
      </div>

      <div class="container mt-5">
        <h4 class="mb-4">Program Application Details</h4>
        <table
          class="table table-bordered table-sm mb-4"
          style="font-size: 20px;"
        >
          <tbody>
            <tr>
              <td width="35%">Name of HEI</td>
              <th>{{ hei.name }}</th>
            </tr>
            <tr>
              <td>Applied Program</td>
              <th>{{ program.program_name }}</th>
            </tr>
            <tr>
              <td>Applied for</td>
              <th>{{ form.application_type }}</th>
            </tr>
            <tr>
              <td>A.Y. Effectivity</td>
              <th>{{ form.academic_year }}</th>
            </tr>
          </tbody>
        </table>
        <p style="font-size: 20px;" class="text-justify">
          Your submission shall be forwarded to the Education Supervior Incharge
          of the program and shall conduct documentary analysis of your
          application to determine the completeness and compliance with the
          requirements of CHED. We shall inform you of the result of the
          documentary analysis as soon as it is completed.
        </p>

        <p class="text-justify" style="font-size: 20px;">
          If you have any questions or concerns, feel free to reach out to us
          via our official email address,
          <strong>chedcaraga@ched.gov.up</strong>, or by giving us a call at
          <strong>(085) 817-3101</strong>.
        </p>
        <p class="text-justify" style="font-size: 20px;">
          Additionally, we encourage you to explore our official website at
          <strong>chedcaraga.ph</strong>, where you can find valuable
          information and resources. Thank you.
        </p>
      </div>
    </section>
  </div>
</template>

<script>
import { awardYears } from '../js/award-years';

export default {
  name: 'ProgramApplication',
  data() {
    return {
      awardYears: awardYears,
      loaderMsg:
        'Uploading file. This may take a while. Do not refresh or close this page...',
      getStarted: false,
      heis: [],
      checkbox: false,
      currentStep: 1,
      requirements: [],
      fileUploadLoader: false,
      file: '',
      form: new Form({
        id: '',
        hei_code: '',
        program_id: '',
        application_type: '',
        academic_year: '',
        status: '',
      }),
      prefix: 'https://drive.google.com/file/d/',
      hasSubmittedSuccessfully: false,
      countdown: 5,
      timerEnabled: false,
      showSurveySection: false,
      submitAnotherEntry: false,
      isDisabled: true,
      isSubmitBtnDisabled: false,
      hei: '',
      program: '',
    };
  },

  methods: {
    openUrl(path) {
      const url = this.prefix + path + '/view';
      window.open(url, '_blank');
    },
    clearAttachments() {
      this.attachments = [];
      let el = document.getElementById('file-upload');
      el.value = '';
    },

    getNextId() {
      axios
        .get('api/program-application/next-id')
        .then((res) => {
          this.form.id = res.data;
          this.getStarted = true;
          this.nextStep();
        })
        .catch((err) => {
          toastr.error('Something went wrong.', 'Oops.');
        });
    },
    uploadFileEvent(event, index, req) {
      this.file = event.target.files[0];
      this.uploadFileToGoogleDrive(index, req);
    },

    print() {
      this.submitAnotherEntry = true;
      setTimeout(() => {
        this.$htmlToPaper('acknowledgement-letter');
      }, 1000);
    },

    uploadFileToGoogleDrive(index, req) {
      let fd = new FormData();
      this.fileUploadLoader = true;

      fd.append('file', this.file);
      fd.append('area_id', index);
      fd.append('requirement_name', req.name);
      fd.append('id', this.form.id);

      $('#progress-bar-modal').modal({
        show: true,
        keyboard: false,
        backdrop: 'static',
      });

      axios
        .post('api/program-application/file-upload', fd, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          $('#progress-bar-modal').modal('hide');
          this.requirements[index].filename = res.data.filename;
          this.requirements[index].path = res.data.path;
          document.getElementById('file-upload').value = '';
          this.file = '';
          this.fileUploadLoader = !this.fileUploadLoader;
          this.isValidated();
        })
        .catch((error) => {
          $('#progress-bar-modal').modal('hide');
          toastr.error(error.response.data.message, 'Error');
        });
    },
    nextStep() {
      if (this.isValidated()) {
        this.currentStep++;
      }
    },
    prevStep() {
      this.currentStep--;
    },

    enableNext() {
      if (this.currentStep == 2 && this.requirements.length != 0) {
        this.isDisabled = false;
      }
    },

    isValidated() {
      if (this.currentStep == 1) {
        return true;
      }

      if (this.currentStep == 2 && this.requirements.length != 0) {
        if (!this.isAppDetailsValidated()) {
          toastr.error('Please fill in all fields.', 'Required fields');
          return false;
        }
        return true;
      }

      if (this.currentStep <= 2 + this.requirements.length) {
        if (this.requirements[this.currentStep - 3].filename == '') {
          toastr.error(
            'Please upload the required document.',
            'Required field'
          );
          return false;
        } else {
          return true;
        }
      }

      if (this.currentStep > 2 + this.requirements.length) {
        return true;
      }
    },

    isAppDetailsValidated() {
      const { academic_year, application_type } = this.form;

      if (
        academic_year == '' ||
        this.hei.code == '' ||
        this.program.id == '' ||
        application_type == ''
      ) {
        return false;
      }

      return true;
    },

    getRequirementsPerAppType() {
      axios
        .get('api/program-application/requirements-by-app-type', {
          params: {
            application_type: this.form.application_type,
          },
        })
        .then((res) => {
          this.requirements = res.data;
          this.requirements = this.requirements.map((obj) => {
            return { ...obj, filename: '', path: '' };
          });
          this.enableNext();
        })
        .catch((err) => {});
    },

    submit() {
      if (
        !confirm(
          'WARNING! \nYou can no longer update this application once submitted. \nAre you sure you want to proceed?'
        )
      ) {
        return;
      }

      this.isSubmitBtnDisabled = true;
      this.loaderMsg = 'Submitting your application...';
      this.fileUploadLoader = true;
      this.form.hei_code = this.hei.code;
      this.form.program_id = this.program.id;

      axios
        .post('api/submit-program-application', this.form)
        .then((res) => {
          this.nextStep();
          this.fileUploadLoader = false;
          this.isSubmitBtnDisabled = false;
        })
        .catch((err) => {});
    },

    getPrograms() {
      axios
        .get('api/program-application-programs')
        .then((response) => {
          this.programs = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getHeis() {
      axios
        .get('api/heis')
        .then((response) => {
          this.heis = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    startTimer() {
      this.timerEnabled = false; // Disable the button during countdown
      let timer = setInterval(() => {
        this.countdown--;
        if (this.countdown <= 0) {
          clearInterval(timer);
          this.timerEnabled = true; // Enable the button after countdown
        }
      }, 1000);
    },

    resetForm() {
      this.loaderMsg =
        'Uploading file. This may take a while. Do not refresh or close this page...';
      this.form.reset();
      this.file = '';
      this.currentStep = 1;
      this.hei = this.program = '';
      this.showSurveySection = this.timerEnabled = this.checkbox = false;
      this.countdown = 5;
    },
  },
  mounted() {
    this.getPrograms();
    this.getHeis();
  },
};
</script>

<style scoped>
ol li {
  padding-bottom: 10px;
  font-size: 15px;
}
</style>
